import React, { useEffect, useState, useRef } from 'react';
import "../components/MainPanel.css";
import { Link } from 'react-router-dom';
import {getViewership, getContentByContentId, getViewerByHash} from './ContentManip';
import BCLoadingPage from '../components/BCLoadingPage';
import Error1 from '../errorManip/Error1';
import Error3 from '../errorManip/Error3';
import Navbar from '../components/Navbar';
import Foot from '../components/Foot';
import {getContentCode, getCpCode} from '../components/CpCode';
import {hoverDeleteHandler, unhoverDeleteHandler} from '../components/ButtonManip';
import orgNameNoStyleHandler from '../components/OrgNameNoStyleManip';
import axios from 'axios';

const Content = ({translator, viewerHash}) => {
    const [viewer, setViewer] = useState(null);
    const [viewerBuyAccess, setViewerBuyAccess] = useState(null);
    const [contentStateId, setContentStateId] = useState("");
    const [content, setContent] = useState(null);
    const videoRef = useRef(null);
    const [videoSrc, setVideoSrc] = useState("");
    const [bcErrorOccured, setBcErrorOccured] = useState(false);
    const [bcErr, setBcErr] = useState({});
    const [viewerNotExistError, setViewerNotExistError] = useState(false);

    useEffect(() => {
        const init = async () => {
            const viewerData = await getViewerByHash(viewerHash).then(res => res.data);
            if(viewerData!==undefined && viewerData.length!==0){
                setViewer(viewerData[0]);
            } else {
                setViewerNotExistError(true);
            }

            const uri = window.location.href;
            const uriArray = uri.split("/");
            const contentStateIdValue = uriArray[uriArray.length - 1];
            setContentStateId(contentStateIdValue);

            const viewership = await getViewership(contentStateIdValue, viewerHash).then(res => res.data);
            const contentData = await getContentByContentId(contentStateIdValue).then(res => res.data);
            setContent(contentData.length===0 ? contentData : contentData[0]);

            const contentViewership = sessionStorage.getItem(contentStateIdValue);
            if(viewership.length!==0 && contentData.length!==0 && contentViewership!==null){
                const contentFound = contentData[0];
                if(JSON.parse(contentViewership).verification===null){
                    try{
                        window.globalThis = window;
                        const n7sdkInstance = window.N7SDK;
                        const n7sdk = new n7sdkInstance();
                        const serviceProvider = contentFound.service_provider_name;
                        const views = viewership[0].views;
                        const viewerMail = viewerData[0].viewer_name;
                        n7sdk.OnChainDBManip.queryNonce(serviceProvider, views, viewerMail).then(async (res) => {
                            const nonce = res;
                            var tokenValue = JSON.parse(sessionStorage.getItem(contentStateIdValue)).token;
                            tokenValue = tokenValue.replace("\/", "/");
                            n7sdk.EncryptionManip.userSign(tokenValue, nonce).then(signedNonce => {
                                let viewer = {
                                    contentProvider: getCpCode(contentFound.content_provider_name),
                                    nonceEncrypted: signedNonce,
                                    suppliedContentId: getContentCode(contentFound.content_unique_id, contentFound.content_provider_name),
                                    viewerId: viewerData[0].viewer_name
                                }
                                let proof = {
                                    contentProvider: getCpCode(contentFound.content_provider_name),
                                    nonce: nonce,
                                    proof: signedNonce,
                                    suppliedContentId: getContentCode(contentFound.content_unique_id, contentFound.content_provider_name),
                                    viewerId: viewerData[0].viewer_name
                                }
                                let asset = {
                                    streamName: contentFound.content_unique_id + "_" + orgNameNoStyleHandler(contentFound.content_provider_name),
                                    publishingFolderName: contentFound.content_unique_id + "_" + orgNameNoStyleHandler(contentFound.content_provider_name)
                                }
                                n7sdk.OnChainDBManip.sendProof(viewer, proof, asset).then(() => {
                                    // wait 3s 
                                    setViewerBuyAccess(true);
                                    const contentViewershipUpdated = JSON.parse(contentViewership);
                                    contentViewershipUpdated.verification = true;
                                    sessionStorage.setItem(contentStateIdValue, JSON.stringify(contentViewershipUpdated));
                                    const videoFolder = contentFound.content_unique_id + "_" + orgNameNoStyleHandler(contentFound.content_provider_name);
                                    const videoSrcString = process.env.REACT_APP_SP_VID_SRC + "/" + videoFolder + process.env.REACT_APP_SP_VID_SRC_SUFFIX; 
                                    setVideoSrc(videoSrcString);
                                }).catch(error => {
                                    setBcErrorOccured(true);
                                    setBcErr({name: error.name, msg: error.message})
                                })
                            }).catch(error => {
                                console.log(error);
                            })
                        }).catch(error => {
                            setBcErrorOccured(true);
                            setBcErr({name: error.name, msg: error.message})
                        })
                    } catch(error){
                        console.error(error);
                    }
                } else {
                    if(JSON.parse(contentViewership).verification===true){
                        setViewerBuyAccess(true);
                        const videoFolder = contentFound.content_unique_id + "_" + orgNameNoStyleHandler(contentFound.content_provider_name);
                        const videoSrcString = process.env.REACT_APP_SP_VID_SRC + "/" + videoFolder + process.env.REACT_APP_SP_VID_SRC_SUFFIX; 
                        setVideoSrc(videoSrcString);
                    } else {
                        setViewerBuyAccess(false);
                    }
                }
            } else {
                setViewerBuyAccess(false);
            }
        };

        init();
    }, [])

    useEffect(() => {
        const loadVideo = async () => {
          if (viewerBuyAccess === true && videoSrc!=="") {
            const video = videoRef.current;
            axios.get(videoSrc).then(() => {
                if (window.Hls.isSupported()) {
                    const hls = new window.Hls();
                    hls.loadSource(videoSrc);
                    hls.attachMedia(video);
                  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = videoSrc;
                  }
            }).catch(() => {
                setTimeout(() => {
                    loadVideo();
                }, 2000);
            })
          }
        };
    
        loadVideo();
    }, [viewerBuyAccess, videoSrc]);

    const viewerHaveAccess = () => {
        return(
            <video ref={videoRef} controls width={"900px"} />
        )
    }

    const viewerDoNotHaveAccess = () => {
        window.location.assign("/" + viewerHash + "/" + contentStateId + "/purchase");
    }

    if(viewerBuyAccess!==null){
        if(content!==null){
            if(content.length===0 || viewerNotExistError){
                return <Error1 translator={translator} />
            } else if(bcErrorOccured){
                return (
                    <Error3 translator={translator} errorType={bcErr.name} errorMsg={bcErr.msg} />
                )
            } else {
                if(viewerBuyAccess === false){
                    viewerDoNotHaveAccess();
                    return null;
                } else {
                    return(
                        <div className='wrapper'>
                            <div className='wrapper'>
                                <Navbar translator={translator} />
                                <div className="main-panel-with-footer">
                                    <div className="video-page-container">
                                        {viewerHaveAccess()}
                                        <Link to={"/" + viewerHash + "/contents"} >
                                            <button className='n7-button delete-button' style={{cursor: "pointer"}} onMouseEnter={hoverDeleteHandler} onMouseLeave={unhoverDeleteHandler}>{translator("return")}</button>
                                        </Link>
                                    </div>
                                    <Foot translator={translator} />
                                </div>
                            </div>
                        </div>
                    )
                }
            }
        }
    } else {
        return <BCLoadingPage translator={translator} />
    }
}

export default Content;
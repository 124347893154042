import React, {useState, useRef, useEffect} from "react";
import {Form, Input} from "antd";
import {Link} from "react-router-dom";
import {Modal, Form as ModalForm, Row, Col} from 'react-bootstrap';
import "../components/UserLogin.css";
import LoadingButton from '../components/LoadingButton';
import {hoverSubmitHandler, unhoverSubmitHandler} from "../components/ButtonManip";
import {findViewer, addViewer} from "../contents/ContentManip";
import PropTypes from "prop-types";
import LoginNavbar from "../components/LoginNavbar";

const SignUp = ({translator}) => {

    const [cognitoAuthSDK, setCognitoAuthSDK] = useState(null);
    const [viewerName, setViewerName] = useState("");
    const [viewerPwd, setViewrPwd] = useState("");
    const [viewerEmail, setViewerEmail] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [showCodeBox, setShowCodeBox] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const form = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const n7sdkInstance = window.N7SDK;
                const n7sdk = new n7sdkInstance();
                setCognitoAuthSDK(n7sdk.CpViewerAuth);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);

    const onFinish = async () => {
        setShowLoader(true);
        try {
            cognitoAuthSDK.registerUser(viewerName, viewerPwd, viewerEmail).then(res => {
                setShowLoader(true);
                setShowCodeBox(true);
            }).catch(err => {
                alert(err);
                window.location.reload();
            })
        } catch(error){
            console.error(error);
        }
    }

    const viewerNameHandler = (event) => {
        setViewerName(event.target.value);
    }
    
    const viewerPwdHandler = (event) => {
        setViewrPwd(event.target.value);
    }

    const viewerEmailHandler = (event) => {
        setViewerEmail(event.target.value);
    }

    const formVerification = () => {
        const userNameNotNull = viewerName!=="";
        const userPwdNotNull = viewerPwd!=="";
        const userEmailNotNull = viewerEmail!=="";
        const userEmailFormatcorrect = validEmailAddress(viewerEmail);
        if(userNameNotNull&&userPwdNotNull&&userEmailNotNull&&userEmailFormatcorrect){
            return(
                <div className="login-submit-btn">
                    <LoadingButton text={translator('send_verification_code')} className="n7-button normal-button" onMouseEnter={(e)=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} onSubmit={onFinish} loading={showLoader} disabled={showLoader} />
                    <Link className="attention-tag-text" to="/">{translator("sign_in")}</Link>
                </div>
            ) 
        } else {
            return (
                <div className="login-submit-btn">
                    <button className="n7-button normal-button disabled-button" disabled>{translator("send_verification_code")}</button>
                    <Link className="attention-tag-text" to="/">{translator("sign_in")}</Link>
                </div>
            )
        }
    }

    const validEmailAddress = (userMail) => {
        const format = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return format.test(String(userMail).toLowerCase())
    }

    const hideCodeBox = () => {
        setShowCodeBox(false);
    }

    const verificationCodeHandler = (event) => {
        setVerificationCode(event.target.value);
    }

    const confirmation = async () => {
        setShowModalLoader(true);
        cognitoAuthSDK.confirmeUser(viewerName, verificationCode).then(async () => {
            const viewer = await findViewer(viewerName).then(res => res.data);
            const foundViewer = viewer.length!==0;
            if(!foundViewer){
                addViewer(viewerName).then(() => {
                    window.location.assign("/");
                }).catch((err) => {
                    alert(err);
                    window.location.reload();
                })
            } else {
                window.location.assign("/");
            }
        }).catch(err => {
            alert(err);
            setShowModalLoader(false);
        })
    }

    return (
        <div className="wrapper">
            <div className="navbar">
                <LoginNavbar />
            </div>
            <div className="login-container">
                <div className="login-form-container">
                    <div className="login-input-container">
                        <Form 
                            name="normal_login"
                            layout="vertical"
                            className="login-inputs"
                        >
                            <Form.Item
                                name="viewer_name"
                                label={translator("user_name")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_name_msg')
                                }]}>
                                <Input  
                                    placeholder={ translator('your_user_name') } 
                                    autoComplete="off"
                                    value={viewerName}
                                    onChange={viewerNameHandler}/>
                            </Form.Item>
                            <Form.Item
                                name="viewer_pwd"
                                label={translator("pwd")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_pwd_msg')
                                }]}>
                                <Input.Password
                                    type="password"
                                    placeholder={translator('your_pwd')}
                                    autoComplete="off"
                                    value={viewerPwd}
                                    onChange={viewerPwdHandler}/>
                            </Form.Item>  
                            <Form.Item
                                name="content_provider_user_email"
                                label={translator("email")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_email')
                                }]}>
                                <Input
                                    style={{ width: "300px" }}
                                    placeholder={translator('your_email')}
                                    autoComplete="off"
                                    value={viewerEmail}
                                    onChange={viewerEmailHandler}/>
                            </Form.Item>  
                        </Form>
                    </div>
                    {formVerification()}
                </div>
            </div>
            <div className="footer">
                <div className="footer-left-panel">
                    <div className="footer-left-panel-about-n7">
                        <a id="about-n7-link" href="#">
                            {translator('about_n7_project')}
                        </a>
                    </div>
                    <div className="footer-left-panel-contact">
                        <a id="contact-link" href="#">
                            {translator('contact_n7')}
                        </a>
                    </div>
                </div>
                <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
            </div>
            <Modal show={showCodeBox} onHide={hideCodeBox} style={{ position: "fixed", zIndex: "9999" }}>
                <Modal.Header>
                    <Modal.Title>
                        {translator('verification_code')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="user-confirmation">
                    <ModalForm ref={form}>
                        <ModalForm.Group as={Row} className="mb-3">
                            <Col>
                                <ModalForm.Control type="text" required onChange={event=>{verificationCodeHandler(event)}} />
                            </Col>
                        </ModalForm.Group>
                    </ModalForm>
                </Modal.Body>
                <Modal.Footer>
                    { verificationCode=="" ? 
                        <button className="n7-button normal-button disable-button" disabled >{translator("validate")}</button> : 
                        <LoadingButton text={translator("validate")} onMouseEnter={hoverSubmitHandler} onMouseLeave={unhoverSubmitHandler} onSubmit={confirmation} loading={showModalLoader} disabled={showModalLoader} /> 
                    }
                    </Modal.Footer>
            </Modal>
        </div>
    )  
}

SignUp.propTypes = {
    setUser: PropTypes.func.isRequired,
};

export default SignUp;

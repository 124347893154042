import React, { Component } from "react";

const Error3 = ({translator, errorType, errorMsg}) => {
    return(
        <div className="error-container">
            <div className="error-main-panel">
                <img className="error-img" src={require("../components/icons/error3.png")} />
                <div className="error-info-panel">
                    <div className="error-type">
                        {errorType}
                    </div>
                    <div className="error-msg-panel">
                        <div className="error-img-title">
                            
                        </div>
                        <div className="error-msg">
                            {errorMsg==="" ? "" : errorMsg + "."} 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Error3;
import axios from "axios";
import getToken from "../userLogin/GetToken";

const instance = axios.create();
instance.interceptors.request.use(function (config) {
    const accessToken = getToken(); 
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    return config;
  }, function (error) {
    return Promise.reject(error);
});

const BASIC_URL = process.env.REACT_APP_N7_OFFCHAIN_API_URL;

const getAllContents = () => {
    const url = BASIC_URL + "/contents";
    return instance.get(url);
}

const getCps = () => {
    const url = BASIC_URL + "/cps";
    return instance.get(url);
}

const getViewerByHash = (viewerHash) => {
    const url = BASIC_URL + "/" + viewerHash;
    return instance.get(url);
}

const getContentByContentId = (contentStateId) => {
    const url = BASIC_URL + "/contentState/" + contentStateId;
    return instance.get(url);
}

const getViewership = (contentStateId, viewerHash) => {
    const url = BASIC_URL + "/content/" + contentStateId + "/viewership/" + viewerHash;
    return instance.get(url);
}

const findViewer = (viewerName) => {
    const encodedName = encodeURIComponent(viewerName);
    const url = BASIC_URL + "/name/" + encodedName;
    return instance.get(url);
}

const addViewer = (viewerMail) => {
    let newViewer = {
        viewer: viewerMail
    }
    const url = BASIC_URL + "/viewer";
    return instance.post(url, newViewer);
}

const createPurchaseState = (contentStateId, viewer) => {
    let info = {
        viewerId: viewer,
        contentStateId: contentStateId,
        views: 1
    }
    const url= BASIC_URL + "/viewership";
    return instance.post(url, info);
}
    
const addViews = (contentStateId, viewerId, views) => {
    let info = {
        viewerId: viewerId,
        contentStateId: contentStateId,
        views: views
    }
    const url = BASIC_URL + "/views";
    return instance.put(url, info);
}

export {getAllContents, getCps, getViewerByHash, getContentByContentId, getViewership, findViewer, addViewer, createPurchaseState, addViews};
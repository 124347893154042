import React, { useState, useEffect } from "react";
import CryptoJs from "crypto-js";
import { Link } from "react-router-dom"; 
import { Form, Input } from "antd";
import "../components/UserLogin.css";
import LoadingButton from "../components/LoadingButton";
import {hoverSubmitHandler, unhoverSubmitHandler} from "../components/ButtonManip";
import {findViewer} from "../contents/ContentManip";
import PropTypes from 'prop-types';
import LoginNavbar from "../components/LoginNavbar";


const SignIn = ({translator, setUser}) => {
    const [cognitoAuthSDK, setCognitoAuthSDK] = useState(null);
    const [viewerName, setViewerName] = useState("");
    const [viewerPwd, setViewerPwd] = useState("");
    const [showLoader, setShowLoader] = useState(false);

    const getClientHashId = (viewerId) => {
        var hash = CryptoJs.SHA256(viewerId.toString());
        var hashHex = hash.toString(CryptoJs.enc.Hex);
        var hashId = hashHex.substring(0, 8);
        return hashId;
    }

    const saveUsernameToSessionStorage = (username) => {
        return new Promise((resolve, reject) => {
          try {
            setUser(username);
            resolve(); 
          } catch (error) {
            reject(error);
          }
        });
    }

    const onFinish = async() => {
        setShowLoader(true);
        try{
            cognitoAuthSDK.login(viewerName, viewerPwd, async (error, token) => {
                if(error) {
                    alert(translator("wrong_user_name_or_wrong_pwd"));
                    setShowLoader(false);
                } else {
                    saveUsernameToSessionStorage(viewerName).then(async () => {
                        var viewer = await findViewer(viewerName).then(res => res.data);
                        const foundViewer = viewer.length!==0;
                        if(!foundViewer){
                            alert(translator("wrong_user_name_or_wrong_pwd"));
                            window.location.reload();
                        } else {
                            const viewerId = viewer[0].viewer_id;
                            const hashViewerId = getClientHashId(viewerId);
                            const uri = "/" + hashViewerId + "/contents";
                            window.location.assign(uri);
                        
                        }
                    })
                }
            })
        } catch(error){
            alert(error);
        }
    }

    const viewerNameHandler = (event) => {
        setViewerName(event.target.value);
    }
    
    const viewerPwdHandler = (event) => {
        setViewerPwd(event.target.value);
    }

    const formVerification = () => {
        const userNameNotNull = viewerName!=="";
        const userPwdNotNull = viewerPwd!=="";
        if(userNameNotNull&&userPwdNotNull){
            return (
                <div className="login-submit-btn">
                    <LoadingButton text={translator('sign_in')} className="n7-button normal-button" onMouseEnter={(e)=>{hoverSubmitHandler(e)}} onMouseLeave={e=>{unhoverSubmitHandler(e)}} onSubmit={onFinish} loading={showLoader} disabled={showLoader} />
                    <Link className="attention-tag-text" to="/sign-up">{translator("sign_up")}</Link>
                </div>
                
            )
        } else {
            return (
                <div className="login-submit-btn">
                    <button className="n7-button normal-button disabled-button" disabled>{translator("sign_in")}</button>
                    <Link className="attention-tag-text" to="/sign-up">{translator("sign_up")}</Link>
                </div>
            )
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const n7sdkInstance = window.N7SDK;
                const n7sdk = new n7sdkInstance();
                setCognitoAuthSDK(n7sdk.CpViewerAuth);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="wrapper">
            <div className="navbar">
                <LoginNavbar />
            </div>
            <div className="login-container">
                <div className="login-form-container">
                    <div className="login-input-container">
                        <Form 
                            name="normal_login"
                            layout="vertical"
                            className="login-inputs"
                        >
                            <Form.Item
                                name="content_provider_user_name"
                                label={translator("user_name")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_name_msg')
                                }]}>
                                <Input 
                                    placeholder={translator('your_user_name')} 
                                    autoComplete="off"
                                    value={viewerName}
                                    onChange={viewerNameHandler}/>
                            </Form.Item>
                            <Form.Item
                                name="content_provider_user_pwd"
                                label={translator("pwd")}
                                rules={[{
                                    required: true,
                                    message: translator('enter_user_pwd_msg')
                                }]}>
                                <Input.Password
                                    type="password"
                                    placeholder={translator('your_pwd')}
                                    autoComplete="off"
                                    value={viewerPwd}
                                    onChange={viewerPwdHandler}/>
                            </Form.Item>  
                        </Form>
                    </div>
                    <div className="login-submit-btn">
                        {formVerification()}
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="footer-left-panel">
                    <div className="footer-left-panel-about-n7">
                        <a id="about-n7-link" href="#">
                            {translator('about_n7_project')}
                        </a>
                    </div>
                    <div className="footer-left-panel-contact">
                        <a id="contact-link" href="#">
                            {translator('contact_n7')}
                        </a>
                    </div>
                </div>
                <div className="footer-right-panel">&copy; {1900 + new Date().getYear()}, v1, {translator('made_by_n7_development_team')}</div>
            </div>
        </div>
    )
}

SignIn.propTypes = {
    setUser : PropTypes.func.isRequired
}

export default SignIn;
